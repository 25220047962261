//
// Alert
// --------------------------------------------------


.alert-secondary {
  border-color: $gray-400;
  background-color: $secondary;
}
.alert-secondary,
.alert-light {
  color: $body-color;
  a:not(.btn) { color: $gray-800 !important; }
}
.alert-dark {
  border-color: theme-color('dark');
  background-color: theme-color('dark');
  color: $white;
  > *,
  a:not(.btn) { color: $white !important; }
}
.alert a:not(.btn) { 
  text-decoration: underline;
  &:hover { text-decoration: none; }
}


// Alert close

.alert .close { transition: color .2s; }
