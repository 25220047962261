//
// Mixins
// --------------------------------------------------


// Font smoothing

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// Appearance

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}


// Body font size variants

@mixin font-size-variant($class, $size) {
  #{$class} {
    @include font-size($size, true);
  }
}


// Opacity variants

@mixin opacity-variant($class, $value) {
  .opacity-#{$class} {
    opacity: $value !important;
  }
}


// Background-position variants

@mixin background-position-variant($class, $value) {
  .bg-position-#{$class} {
    background-position: $value !important;
  }
}


// Override default Bootstrap List Groups mixin

@mixin list-group-item-variant-custom($state, $background, $color) {
  .list-group-item-#{$state} {
    color: $color;
    background-color: $background;

    &.list-group-item-action {
      @include hover-focus {
        color: darken($color, 8%);
        background-color: darken($background, 3%);
      }
    }
  }
}


// Gradient background

@mixin gradient-background($gradient-from: $gradient-from-color, $gradient-to: $gradient-to-color) {
  background: $gradient-from !important;
  background: linear-gradient(55.95deg, $gradient-from 0%, $gradient-to 100%) !important;
}


// Multiple items easing

@mixin multi-easing($count-to, $duration, $delay-factor, $easing) {
  @for $i from 1 through $count-to {
    &:nth-child(#{$i}) {
      transition: all #{$duration}s #{$i * $delay-factor}s $easing;
    }
  }
}
