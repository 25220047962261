//
// Placeholders
// --------------------------------------------------

// Absolute alignment center
%center-xy {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}


// Absolute stretching
%stretch-xy {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
