// SEO & Marketing Agency demo overrides

// Demo specific overrides
$primary: #2053a1;

// Gradient
$gradient-from-color:   $primary;
$gradient-to-color:     #3867ad;


$path-to-icons:         url('../fonts/createx-icons.ttf?h4y910') format('truetype'),
                        url('../fonts/createx-icons.woff?h4y910') format('woff'),
                        url('../fonts/createx-icons.svg?h4y910#createx-icons') format('svg') !default;


// Import core theme styles
@import './core-theme.scss';
